import { useMemo, useState } from "react";
import { Card, ListGroup } from "react-bootstrap";
import arrowDownIcon from "../../../../assets/icons/arrow-down.png";
import arrowUpIcon from "../../../../assets/icons/arrow-up.png";
import styles from "./summary.module.css";
import { useSelector, useDispatch } from "react-redux";
import { summaryKeysObject } from "../utility/constant";
import { updateSummaryInfoAction } from "store/actions/sample_strategy/sampleStrategyAction";

const Summary = () => {
  const dispatch = useDispatch();

  const sampleStrategyReport = useSelector(
    (state) => state.sampleStrategyReport
  );
  const {
    reportData: { summary = {} },
  } = sampleStrategyReport;
  const [showDetails, setShowDetails] = useState(false);
  const [showSample, setShowSample] = useState(false);


  const toggleSample = () => {
    setShowSample((prev) => !prev); 
    if (showDetails) {
      setShowDetails((prev) => !prev);
    }
  }
  const toggleDetails = () => setShowDetails((prev) => !prev);

  const arrowIcon = useMemo(
    () => (showDetails ? arrowUpIcon : arrowDownIcon),
    [showDetails]
  );

  const handleSummaryOnChange = (e, field) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");

    const checkValue = value.includes(".") ? 8 : 7;

    if (value.length > checkValue) {
      return value.slice(0, 7);
    }

    const key = field.key;
    dispatch(updateSummaryInfoAction({ key, value }));
  };

  const handleSummaryOnBlur = (e, field) => {
    let value = e.target.value.replace(/[^0-9.]/g, "") || field.defaultValue;
    const key = field.key;

    if (value.length <= 5) {
      value = parseFloat(value).toFixed(2);
    } else if (value.includes(".")) {
      return value;
    } else {
      let integerPart = value.slice(0, 5);
      let decimalPart = value.slice(5, 7);
      value = integerPart + "." + decimalPart;
    }

    dispatch(updateSummaryInfoAction({ key, value }));
  };

  return (
    <div className={styles.Summary}>
      <button
        className={styles.header}
        onClick={toggleSample}
        style={{ cursor: "pointer" }}
      >
        <div className={styles.icon}>
          <img src={arrowIcon} alt="Toggle Details" />
          <span>Summary</span>
        </div>
      </button>

      
        <button
          className={`${styles.subHeader} ${showSample ? styles.showSubHeader : styles.hideSubHeader}`}
          onClick={toggleDetails}
          style={{ cursor: "pointer", "&:focus-visible": { outline: "none !important"} }}
        >
           {showSample && ( <>
          <div className={styles.icon}>
            <img src={arrowIcon} alt="Toggle Details" />
            <span>{summaryKeysObject.sampling_balance.label}</span>
          </div>
          <span className={styles.balance}>
            ${summary[summaryKeysObject.sampling_balance.key]}
          </span>
          </>
           )}
        </button>
      



      
        <Card className={`${styles.summaryDetailContainer} ${showDetails ? styles.summaryShowDetailContainer : styles.summaryHideDetailContainer}`}>
        {showDetails && (
          <ListGroup variant="flush">
            {Object.keys(summaryKeysObject)
              .filter((key) => !summaryKeysObject[key].displayTop)
              .map((key, index) => {
                const field = summaryKeysObject[key];
                const value = "$" + summary[field.key];
                if (field.key === "total_discount") {
                  if (summary[field.key] != 0) {
                    return (
                      <ListGroup.Item
                        key={index}
                        className={field.isTotal ? styles.total : styles.listItem}
                      >
                        <span>{field.label}</span>
                        {field.editable ? (
                          <div className={styles.input_wrapper}>
                            <input
                              name={field.key}
                              className={styles.editorInput}
                              type="text"
                              value={value}
                              onChange={(e) => handleSummaryOnChange(e, field)}
                              onBlur={(e) => handleSummaryOnBlur(e, field)}
                            />
                          </div>
                        ) : (
                          <span className={styles.price}>{value}</span>
                        )}
                      </ListGroup.Item>
                    );
                  }
                }
                else {
                  return (
                    <ListGroup.Item
                      key={index}
                      className={field.isTotal ? styles.total : styles.listItem}
                    >
                      <span>{field.label}</span>
                      {field.editable ? (
                        <div className={styles.input_wrapper}>
                          <input
                            name={field.key}
                            className={styles.editorInput}
                            type="text"
                            value={value}
                            onChange={(e) => handleSummaryOnChange(e, field)}
                            onBlur={(e) => handleSummaryOnBlur(e, field)}
                          />
                        </div>
                      ) : (
                        <span className={styles.price}>{value}</span>
                      )}
                    </ListGroup.Item>
                  );
                }
              })}
          </ListGroup>
        )}
        </Card>
      

    </div>
  );
};

export default Summary;
