import {
  GET_TEST_LIST_FAIL,
  GET_TEST_LIST_REQUEST,
  GET_TEST_LIST_SUCCESS,
  GET_INSPECTOR_LIST_FAIL,
  GET_INSPECTOR_LIST_REQUEST,
  GET_INSPECTOR_LIST_SUCCESS,
  GET_REPORT_DATA_REQUEST,
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_FAIL,
  ROW_SELECTION_REQUEST,
  ROW_SELECTION_SUCCESS,
  ROW_SELECTION_FAIL,
  CLIENT_FLAG_SELECTION_FAIL,
  CLIENT_FLAG_SELECTION_SUCCESS,
  CLIENT_FLAG_SELECTION_REQUEST,
  UPDATE_HEADER_INFO_SUCCESS,
  UPDATE_HEADER_INFO_REQUEST,
  UPDATE_HEADER_INFO_FAIL,
  UPDATE_STRATEGY_REPORT_TABLE_LIST_REQUEST,
  UPDATE_STRATEGY_REPORT_TABLE_LIST_SUCCESS,
  UPDATE_STRATEGY_REPORT_TABLE_LIST_FAIL,
  SAVE_REPORT_DATA_REQUEST,
  SAVE_REPORT_DATA_SUCCESS,
  SAVE_REPORT_DATA_FAIL,
  REFRESH_REPORT_DATA_FAIL,
  REFRESH_REPORT_DATA_REQUEST,
  REFRESH_REPORT_DATA_SUCCESS,
  PUBLISH_REPORT_DATA_REQUEST,
  PUBLISH_REPORT_DATA_SUCCESS,
  PUBLISH_REPORT_DATA_FAIL,
  UPDATE_SUMMARY_INFO_FAIL,
  UPDATE_SUMMARY_INFO_SUCCESS,
  UPDATE_SUMMARY_INFO_REQUEST,
  UPDATE_SAMPLE_COST_LIST,
} from "../../types/sample_strategy/sampleStrategyTypes";
import axios from "../../../axios-defaults";
import { handleReduxError } from "main/shared/helpers";
import {
  columnsNames,
  dbEditKeys,
} from "main/pages/SampleStrategy/utility/constant";
import {
  handleCustomValidationAndUpdateColumnOnDemand,
  handleDiscountUpdateLogic,
  handleEmptyColumns,
  compareSampleReports,
  handleSampleCostUpdateLogic,
  markRowsAndColFalse,
  setColumnEditTrue,
} from "main/pages/SampleStrategy/utility/helper";

export const getReportDataAction = (inspection_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REPORT_DATA_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data = {} } = await axios.get(
      `api/v1/sample-strategy/${inspection_id}`,
      config
    );

    handleEmptyColumns(data);

    dispatch({
      type: GET_REPORT_DATA_SUCCESS,
      payload: data,
    });
    return await data;
  } catch (error) {
    const err = handleReduxError(error);
    dispatch({
      type: GET_REPORT_DATA_FAIL,
      payload: err,
    });
    throw Error(err);
  }
};

export const getReportDataActionCTA = (inspection_id, old_data, testList) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REPORT_DATA_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data = {} } = await axios.get(
      `api/v1/sample-strategy/${inspection_id}`,
      config
    );

    const mergeData = compareSampleReports(old_data?.sample_strategy_report, data?.sample_strategy_report);
    const newData = {...data, sample_strategy_report: mergeData}

    handleEmptyColumns(newData);

    dispatch({
      type: GET_REPORT_DATA_SUCCESS,
      payload: newData,
    });

    getTestListAction();
    
    
    return await newData;
  } catch (error) {
    const err = handleReduxError(error);
    dispatch({
      type: GET_REPORT_DATA_FAIL,
      payload: err,
    });
    throw Error(err);
  }
};

export const getTestListAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_TEST_LIST_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data = [] } = await axios.get(`api/v1/tests/?all=true`, config);

    const updatedList = data.map((test) => {
      const { id, name, test_samples } = test;
      const { cost } = test_samples[0];
      return { id, name, cost };
    });

    dispatch({
      type: UPDATE_SAMPLE_COST_LIST,
      payload: updatedList,
    });

    data?.length &&
      data.forEach((test) => {
        test.testId = test.id;
        test.testName = test.name;
      });
    dispatch({
      type: GET_TEST_LIST_SUCCESS,
      payload: data,
    });
    return await data;
  } catch (error) {
    const err = handleReduxError(error);
    dispatch({
      type: GET_TEST_LIST_FAIL,
      payload: err,
    });
    throw Error(err);
  }
};

export const getInspectorListAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_INSPECTOR_LIST_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data = [] } = await axios.get(`api/v1/inspectors?all=true`, config);
    data?.length &&
      data.forEach((inspector) => {
        inspector.inspectorId = inspector.id;
        inspector.inspectorName = `${inspector?.first_name} ${inspector?.last_name}`;
      });
    dispatch({
      type: GET_INSPECTOR_LIST_SUCCESS,
      payload: data,
    });
    return await data;
  } catch (error) {
    const err = handleReduxError(error);
    dispatch({
      type: GET_INSPECTOR_LIST_FAIL,
      payload: err,
    });
    throw Error(err);
  }
};

export const handleRowSelectionAction = (row) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ROW_SELECTION_REQUEST,
    });
    const {
      sampleStrategyReport: { reportData = {} },
    } = getState();

    const cloneReportData = JSON.parse(JSON.stringify(reportData));
    const {
      sample_strategy_report = [],
      summary,
      discount = {},
    } = cloneReportData;

    sample_strategy_report.forEach((r) => {
      if (r.id === row.id) {
        r[columnsNames.status.value] = !r[columnsNames.status.value];
        r[dbEditKeys.rowEdit] = true;
      }
    });
    handleSampleCostUpdateLogic(sample_strategy_report, summary);
    handleDiscountUpdateLogic(sample_strategy_report, summary, discount);
    dispatch({
      type: ROW_SELECTION_SUCCESS,
      payload: cloneReportData,
    });
    dispatch({
      type: GET_REPORT_DATA_SUCCESS,
      payload: cloneReportData,
    });
  } catch (error) {
    const err = handleReduxError(error);
    dispatch({
      type: ROW_SELECTION_FAIL,
      payload: err,
    });
    throw Error(err);
  }
};

export const handleAllSelectionAction = (e) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ROW_SELECTION_REQUEST,
    });
    const {
      sampleStrategyReport: { reportData = {} },
    } = getState();

    const cloneReportData = JSON.parse(JSON.stringify(reportData));
    const {
      sample_strategy_report = [],
      summary,
      discount = {},
    } = cloneReportData;

    sample_strategy_report.forEach((r) => {
        r[columnsNames.status.value] = e.target.checked;
        r[dbEditKeys.rowEdit] = true;
    });
    handleSampleCostUpdateLogic(sample_strategy_report, summary);
    handleDiscountUpdateLogic(sample_strategy_report, summary, discount);
    dispatch({
      type: ROW_SELECTION_SUCCESS,
      payload: cloneReportData,
    });
    dispatch({
      type: GET_REPORT_DATA_SUCCESS,
      payload: cloneReportData,
    });
  } catch (error) {
    const err = handleReduxError(error);
    dispatch({
      type: ROW_SELECTION_FAIL,
      payload: err,
    });
    throw Error(err);
  }
};

export const handleFlagSelectionAction =
  (row) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CLIENT_FLAG_SELECTION_REQUEST,
      });
      const {
        sampleStrategyReport: { reportData = {} },
      } = getState();

      const cloneReportData = JSON.parse(JSON.stringify(reportData));
      const {
        sample_strategy_report = [],
        summary,
        discount = {},
      } = cloneReportData;

      sample_strategy_report.forEach((r) => {
        if (r.id === row.id) {
          r[columnsNames.client_approval.value] =
            !r[columnsNames.client_approval.value];
          r[dbEditKeys.rowEdit] = true;
        }
      });
      handleSampleCostUpdateLogic(sample_strategy_report, summary);
      handleDiscountUpdateLogic(sample_strategy_report, summary, discount);
      dispatch({
        type: CLIENT_FLAG_SELECTION_SUCCESS,
        payload: cloneReportData,
      });
      dispatch({
        type: GET_REPORT_DATA_SUCCESS,
        payload: cloneReportData,
      });
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: CLIENT_FLAG_SELECTION_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const updateHeaderInfoAction = (data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_HEADER_INFO_REQUEST,
    });
    const {
      sampleStrategyReport: { reportData = {} },
    } = getState();

    const cloneReportData = JSON.parse(JSON.stringify(reportData));
    const { report_header = {} } = cloneReportData;
    report_header[data.key] = data.value;

    dispatch({
      type: UPDATE_HEADER_INFO_SUCCESS,
      payload: cloneReportData,
    });
    dispatch({
      type: GET_REPORT_DATA_SUCCESS,
      payload: cloneReportData,
    });
  } catch (error) {
    const err = handleReduxError(error);
    dispatch({
      type: UPDATE_HEADER_INFO_FAIL,
      payload: err,
    });
    throw Error(err);
  }
};

export const updateStrategyReportTableListAction =
  (row, key) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_STRATEGY_REPORT_TABLE_LIST_REQUEST,
      });
      const {
        sampleStrategyReport: { reportData = {}, testList = [] },
      } = getState();
      const cloneReportData = JSON.parse(JSON.stringify(reportData));
      const {
        sample_strategy_report = [],
        summary = {},
        discount = {},
      } = cloneReportData;

      sample_strategy_report.forEach((r) => {
        if (r.id === row.id) {
          setColumnEditTrue(key, r);
          r[dbEditKeys.rowEdit] = true;
        }
      });

      handleCustomValidationAndUpdateColumnOnDemand({
        key,
        row,
        sample_strategy_report,
        testList,
        summary,
        discount,
      });

      dispatch({
        type: UPDATE_STRATEGY_REPORT_TABLE_LIST_SUCCESS,
        payload: cloneReportData,
      });
      dispatch({
        type: GET_REPORT_DATA_SUCCESS,
        payload: cloneReportData,
      });
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: UPDATE_STRATEGY_REPORT_TABLE_LIST_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const updateSummaryInfoAction = (data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_SUMMARY_INFO_REQUEST,
    });
    const {
      sampleStrategyReport: { reportData = {} },
    } = getState();

    const cloneReportData = JSON.parse(JSON.stringify(reportData));
    const { summary = {} } = cloneReportData;
    summary[data.key] = data.value;
    dispatch({
      type: UPDATE_SUMMARY_INFO_SUCCESS,
      payload: cloneReportData,
    });
    dispatch({
      type: GET_REPORT_DATA_SUCCESS,
      payload: cloneReportData,
    });
  } catch (error) {
    const err = handleReduxError(error);
    dispatch({
      type: UPDATE_SUMMARY_INFO_FAIL,
      payload: err,
    });
    throw Error(err);
  }
};

export const saveReportDataAction =
  (inspection_id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SAVE_REPORT_DATA_REQUEST,
      });
      const {
        sampleStrategyReport: { reportData },
      } = getState();
      const cloneReportData = JSON.parse(JSON.stringify(reportData));

      const body = {
        data: JSON.stringify(reportData),
      };
      const { data: responseData = {} } = await axios.post(
        `api/v1/sample-strategy/${inspection_id}/save`,
        body
      );
      markRowsAndColFalse(cloneReportData);
      dispatch({
        type: SAVE_REPORT_DATA_SUCCESS,
        payload: responseData,
      });
      dispatch({
        type: GET_REPORT_DATA_SUCCESS,
        payload: cloneReportData,
      });
      return await responseData;
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: SAVE_REPORT_DATA_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const refreshReportDataAction = (inspection_id) => async (dispatch) => {
  try {
    dispatch({
      type: REFRESH_REPORT_DATA_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data = {} } = await axios.get(
      `api/v1/sample-strategy/${inspection_id}?reset=true`,
      config
    );

    handleEmptyColumns(data);

    dispatch({
      type: REFRESH_REPORT_DATA_SUCCESS,
    });
    dispatch({
      type: GET_REPORT_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const err = handleReduxError(error);
    dispatch({
      type: REFRESH_REPORT_DATA_FAIL,
      payload: err,
    });
    throw Error(err);
  }
};

export const publishReportDataAction =
  (inspection_id, type) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PUBLISH_REPORT_DATA_REQUEST,
      });
      const {
        sampleStrategyReport: { reportData },
      } = getState();
      const cloneReportData = JSON.parse(JSON.stringify(reportData));

      const body = {
        data: JSON.stringify(reportData),
      };
      const { data: responseData = {} } = await axios.post(
        `api/v1/sample-strategy/${inspection_id}/publish?type=${type}`,
        body
      );
      markRowsAndColFalse(cloneReportData);
      dispatch({
        type: PUBLISH_REPORT_DATA_SUCCESS,
        payload: responseData,
      });
      dispatch({
        type: GET_REPORT_DATA_SUCCESS,
        payload: cloneReportData,
      });
      return await responseData;
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: PUBLISH_REPORT_DATA_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };
