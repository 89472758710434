/* eslint-disable react-hooks/exhaustive-deps */
import {
  faArrowCircleUp,
  faCaretRight,
  faPencilAlt,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { AsYouType } from "libphonenumber-js";
import DatePicker from "main/components/DatePicker/DatePicker";
import TimePicker from "main/components/DatePicker/TimePicker";
import React, { Suspense, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { SET_CONFIRMATION_MODAL } from "store/actions";
import {
  deleteInspectionbyId,
  getInspectionbyId,
  getInspectors,
  makeLeadByID,
  updateInspectionbyId,
} from "../../../../services/InspectionService";
import CheckBoxModal from "../../../components/CheckBox";
import CircleProgress from "../../../components/CircleProgress";
import {
  captalizeFirst,
  checkStatus,
  getAddress,
  getErrorMsg,
  setHeader,
  showToast,
} from "../../../shared/helpers";
import { Delete } from "../../Comments";
import ViewInspectorsModal from "./ViewInspectorsModal";
import { isSampleStrategyReportShown } from "main/pages/SampleStrategy/utility/companySpecific";
const GenerateCOCModal = React.lazy(() =>
  import("./GenerateCOC/GenerateCOCModal")
);

export default function CompanyDetails() {
  const user = useSelector((state) => state.user);

  const [loader, setloader] = useState(true);
  const [errors, setErrors] = useState({});
  const [editEnabled, setEditEnabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [opendel, setopendel] = useState(false);
  const [index, setindex] = useState(0);
  const [update, setupdate] = useState(0);
  const [delid, setdelid] = useState(0);
  const [refreshInfo, setRefreshInfo] = useState(false);
  const [pay, setpay] = useState({
    inspectors: [],
  });
  const [datet, setdatet] = useState("");
  const [timet, settimet] = useState("");
  const { insp_id } = useParams();

  // generate coc
  const [generateModalShow, setgenerateModalShow] = React.useState(false);
  const [insption_data, setInsption_data] = useState();

  const [generalInfo, setgeneralInfo] = useState({});

  let defaultAvatar = require("../../../../assets/icons/person.png").default;

  // view more
  const [viewMorelink, setviewMorelink] = useState(false);
  const [viewMoreloader, setviewMoreloader] = useState(false);
  const viewMoreopen = () => {
    setviewMorelink(true);
  };
  // Inspectors list
  const [inspectlink, setinspectlink] = useState(false);
  const [inspectloader, setinspectloader] = useState(true);
  const [inspectdata, setinspectdata] = useState(false);
  const [inspectpage, setinspectpage] = useState(1);

  const dispatch = useDispatch();

  const propertiesPermission = useSelector(
    (state) => state.user.permissions.properties
  );
  const customersPermission = useSelector(
    (state) => state.user.permissions.customers
  );

  const inspectopen = () => {
    setinspectdata(null);
    setinspectlink(true);
    setinspectloader(true);
    getInspectors("", inspectpage)
      .then(({ data: res }) => {
        setinspectdata((state) => ({
          ...res,
          data: state?.data ? [...state.data, ...res.data] : res.data,
        }));
        // setinspectloader(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setinspectloader(false));
  };
  const getInsptions = () => {
    getInspectionbyId(insp_id)
      .then((x) => {
        setInsption_data(x.data);
        setdatet(x.data?.scheduled_time.split(" ")[0]);
        settimet(x.data?.scheduled_time);
        setviewMoreloader(false);
        setloader(false);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setopendel(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    setloader(true);
    getInspectionbyId(insp_id)
      .then((x) => {
        console.log(x.data);
        if (isMounted) {
          setgeneralInfo(x.data);
          setHeader(dispatch, {
            title: `Inspection #${x?.data?.code} - ${getAddress(
              x?.data?.property?.address
            )}`,
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setloader(false));
    return () => {
      isMounted = false;
      setloader(false);
    };
  }, [insp_id, refreshInfo]);

  useEffect(() => {
    Object.keys(generalInfo).length !== 0 && setInsption_data(generalInfo);
    Object.keys(generalInfo).length !== 0 &&
      setdatet(generalInfo?.scheduled_time.split(" ")[0]);
    Object.keys(generalInfo).length !== 0 &&
      settimet(generalInfo?.scheduled_time);
    Object.keys(generalInfo).length !== 0 && setloader(false);
    Object.keys(generalInfo).length === 0 && getInsptions();
    // console.log(utcDateforInput(generalInfo?.scheduled_time));

    return () => {
      setInsption_data({});
      setdatet("");
      settimet("");
      // setviewMoreloader(true);

      setopendel(false);
    };
  }, [generalInfo]);

  const edit = (updateType, inspectors = []) => {
    setIsSaving(true);
    console.log(updateType);
    console.log(insption_data, inspectors);
    let data_for;
    if (updateType === "date") {
      if (datet === "" || timet === "" || insption_data?.notes === "") {
        showToast("Something went wrong.");
        setIsSaving(false);
      } else {
        data_for = {
          id: parseInt(insp_id),
          code: insption_data?.code,
          notes: insption_data?.notes,
          scheduled_time:
            dayjs(datet).format("MM/DD/YY") +
            " " +
            dayjs(timet).format("HH:mm"),
          //formatDate(datet) + " " + time_data[0] + ":" + time_data[1],
        };
        updateByID(insp_id, data_for);
      }
    } else if (updateType === "inspectors") {
      data_for = {
        id: parseInt(insp_id),
        inspectors: inspectors,
      };
      updateByID(insp_id, data_for);
    }
  };

  const updateByID = (insp_id, data_for) => {
    updateInspectionbyId(insp_id, data_for)
      .then((x) => {
        showToast("Inspection updated successfully.", true);
        getInsptions();
      })
      .catch((err) => {
        console.log(err?.response);
        if (err?.response?.status === 422) {
          setErrors(err?.response?.data);
        } else {
          showToast("Something went wrong.");
        }
      })
      .finally(() => {
        // setloader(false);
        setIsSaving(false);
        setEditEnabled(false);
      });
  };

  const makeLead = (inspectorId) => {
    setloader(true);
    makeLeadByID(inspectorId, insp_id)
      .then(() => {
        showToast("Lead inspector changed.", true);
        setRefreshInfo((s) => !s);
        setviewMorelink(false);
      })
      .catch((err) => {
        console.log(err?.response);
        showToast(getErrorMsg(err) || "Something went wrong.");
        setloader(false);
      });
  };

  const openMakeLead = (name, id) => {
    dispatch({
      type: SET_CONFIRMATION_MODAL,
      payload: {
        show: true,
        okText: "Make Lead",
        cancelText: "Cancel",
        title: "Confirmation",
        message: `Are you sure you want to make ${name} the lead inspector?`,
        onOk: () => makeLead(id),
      },
    });
  };

  const RemoveSample = (disable) => {
    disable(true);
    console.log(index, delid, "data");
    let data_del = {
      inspectors_delete: [delid],
    };
    console.log(data_del, "del");
    deleteInspectionbyId(insp_id, data_del)
      .then((x) => {
        console.log(x.data, "asdasdasdas");
        setloader(true);
        showToast("Inspector deleted successfully.", true);
        getInsptions();
      })
      .catch((err) => {
        console.log(err?.response);
        if (err?.response?.status === 422) {
          setErrors(err?.response?.data);
          showToast(
            captalizeFirst(err?.response?.data["inspectors_delete.0"][0])
          );
        } else {
          showToast("Something went wrong.");
        }
      })
      .finally(() => {
        disable(false);
      });
  };

  const openCOC = () => {
    setgenerateModalShow(true);
  };

  const OpenDelete = (id, parentindex) => {
    console.log(id, parentindex);
    setdelid(parentindex);
    setindex(id);
    console.log("object");
    setopendel(true);
  };
  return (
    <>
      {loader ? (
        <div className="spinner-div">
          <Spinner animation="border" variant="success" />
        </div>
      ) : (
        <div className="main-content-area">
          <div className="d-flex justify-content-end">
            {!editEnabled ? (
              <button
                className="btn btn-primary"
                disabled={false}
                onClick={() => setEditEnabled(true)}
                id="edit_inspection_info"
              >
                Edit Inspection Info <FontAwesomeIcon icon={faPencilAlt} />
              </button>
            ) : (
              <div className="d-flex">
                <button
                  className="btn btn-primary"
                  disabled={isSaving}
                  onClick={() => edit("date")}
                >
                  Save
                </button>
                <button
                  className="btn btn-light ml-2 low-priority"
                  onClick={() => {
                    setEditEnabled(false);
                    getInsptions();
                  }}
                  disabled={isSaving}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-md-4 inspectors-onSite">
              <p className="TabsLabel d-flex mb-3 ">Inspectors on Site</p>
              <div className="d-flex" style={{ height: "48px" }}>
                <div className="top-inspector">
                  <img
                    width="120"
                    className="avatar-sm mr-2"
                    src={
                      insption_data?.inspectors[0]?.inspector_details &&
                      insption_data?.inspectors[0]?.inspector_details
                        ?.avatar_url !== null
                        ? insption_data?.inspectors[0]?.inspector_details
                            ?.avatar_url
                        : defaultAvatar
                    }
                    onError={(ev) => (ev.target.src = defaultAvatar)}
                    alt="Avatar"
                  />
                  <strong>
                    {insption_data?.inspectors[0]?.first_name +
                      " " +
                      insption_data?.inspectors[0]?.last_name}
                  </strong>
                </div>
                <button
                  className="btn btn-primary inspection-plus-btn"
                  style={{
                    height: "40px",
                    minWidth: "40px",
                    background: "white",
                    marginTop: "4px",
                    border: "white",
                  }}
                  disabled={false}
                  onClick={inspectopen}
                  id="plus_inspection_onsite"
                >
                  {" "}
                  <FontAwesomeIcon icon={faPlus} style={{ color: "#84857A" }} />
                </button>

                <CheckBoxModal
                  items={inspectdata?.data
                    ?.filter((o1) => {
                      return !insption_data?.inspectors?.some((o2) => {
                        return o1.id === o2.id; // id is unnique both array object
                      });
                    })
                    .map((x) => ({
                      id: x?.id,
                      name: x?.first_name + " " + x?.last_name,
                      image: x?.inspector_details?.avatar_url,
                    }))}
                  onChecked={(items) => console.log(items)}
                  show={inspectlink}
                  onHide={() => setinspectlink(false)}
                  isAvatar={true}
                  defaultAvatar={
                    require("../../../../assets/icons/person.png").default
                  }
                  loader={inspectloader}
                  title={"Add Inspectors"}
                  showTittleIcon={false}
                  buttonLabel={"Save Inspectors"}
                  emptyText={"You already selected all available inspectors"}
                  value={pay.inspectors}
                  onSave={(ev) => {
                    edit("inspectors", ev);
                    setloader(true);
                  }}
                  applyPagination={true}
                  pagination={inspectdata}
                  onPageChange={(page) => {
                    setinspectpage(page);
                    inspectopen();
                  }}
                />
              </div>

              {insption_data?.inspectors.length > 1 &&
                insption_data?.inspectors.slice(1, 3).map((insp, ids) => (
                  <div
                    className="d-flex"
                    style={ids > 0 ? { height: "57px" } : { height: "40px" }}
                    key={insp.id}
                  >
                    <span className="inspector-span-line"></span>
                    <div
                      className="left-div-inspector"
                      style={
                        ids > 0 ? { marginTop: "33px" } : { marginTop: "15px" }
                      }
                    >
                      <div className="other-inspector ">
                        <img
                          width="120"
                          className="avatar-sm mr-2"
                          src={
                            insp?.inspector_details?.avatar_url !== null
                              ? insp?.inspector_details?.avatar_url
                              : defaultAvatar
                          }
                          alt=""
                        />
                        <strong>
                          {insp?.first_name + " " + insp?.last_name}
                        </strong>
                        <button
                          data-tip="Make lead inspector"
                          className="btn btn-primary inspection-plus-btn"
                          style={{
                            height: "40px",
                            minWidth: "40px",
                            background: "transparent",
                            border: "transparent",
                            marginLeft: "auto",
                          }}
                          onClick={() =>
                            openMakeLead(
                              insp?.first_name + " " + insp?.last_name,
                              insp.id
                            )
                          }
                          disabled={false}
                        >
                          <FontAwesomeIcon
                            icon={faArrowCircleUp}
                            style={{ color: "#84857A" }}
                          />
                        </button>
                      </div>
                      <button
                        className="btn btn-primary inspection-plus-btn"
                        style={{
                          height: "40px",
                          minWidth: "40px",
                          background: "transparent",
                          border: "transparent",
                        }}
                        onClick={() => OpenDelete(insp_id, insp.id)}
                        disabled={false}
                        id="plus_inspection_onsite"
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          style={{ color: "#84857A" }}
                        />
                      </button>
                    </div>
                  </div>
                ))}
              <Delete
                show={opendel}
                onHide={() => setopendel(false)}
                msg={"Are you sure you want to delete this inspector?"}
                delfunc={RemoveSample}
              />

              {insption_data.inspectors.length > 3 && (
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary inspection-plus-btn"
                    style={{
                      height: "40px",
                      minWidth: "40px",
                      background: "transparent",
                      border: "transparent",
                      color: "#4aa2b4",
                      margin: "40px 0px 5px 0px",
                    }}
                    disabled={false}
                    onClick={viewMoreopen}
                    id="plus_inspection_onsite"
                  >
                    view more
                  </button>
                  <ViewInspectorsModal
                    show={viewMorelink}
                    onHideview={() => setviewMorelink(false)}
                    items={insption_data.inspectors.map((x) => ({
                      id: x.id,
                      name: x?.first_name + " " + x?.last_name,
                      image: x?.inspector_details?.avatar_url,
                    }))}
                    inspection_id={insp_id}
                    isAvatar={true}
                    defaultAvatar={
                      require("../../../../assets/icons/person.png").default
                    }
                    loader={viewMoreloader}
                    title={"All Inspectors on Site"}
                    buttonLabel={"close"}
                    emptyText={"No inspector selected"}
                    value={pay.inspectors}
                    onSave={(ev) => {
                      setloader(true);
                      getInsptions();
                    }}
                    openMakeLead={openMakeLead}
                  />
                </div>
              )}
            </div>

            <div className="col-md-8">
              <p className="TabsLabel d-flex mb-3 ">Property Info</p>
              <div className="row">
                <div className="col-md-6">
                  <div className="propertyInfoBlock">
                    <div className="row">
                      <div className="col-md-10">
                        <div className="propertyInfoName">
                          {" "}
                          {insption_data?.customer?.first_name +
                            " " +
                            insption_data?.customer?.last_name}
                        </div>
                        <div
                          style={{ maxWidth: "240px" }}
                          className="propertyInfoDetails trunk-text trunk-customer-name"
                        >
                          {insption_data?.customer?.email}
                        </div>
                        <div className="propertyInfoDetails">
                          {new AsYouType("US").input(
                            insption_data?.customer?.phone_number
                          )}
                        </div>
                      </div>
                      <div
                        className={`col-md-2 d-flex align-items-center general-info${
                          !customersPermission && "cursor-disabled"
                        }`}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            cursor: "pointer",
                            ...(!customersPermission && {
                              pointerEvents: "none",
                            }),
                          }}
                          to={`/customers/${insption_data?.customer?.id}`}
                        >
                          <button className="next-btn ripple">
                            <FontAwesomeIcon icon={faCaretRight} />
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    style={{ padding: "0px 0px" }}
                    className=" row propertyInfoBlock d-flex align-items-center general-info"
                  >
                    <div className="col-md-10">
                      <div className=" propertyInfoDetails four-line-ellipsis">
                        {getAddress(insption_data?.property?.address)}{" "}
                      </div>
                    </div>

                    <div
                      className={`col-md-2 d-flex align-items-center general-info${
                        !propertiesPermission && "cursor-disabled"
                      }`}
                    >
                      <Link
                        style={{
                          textDecoration: "none",
                          width: "32px",
                          ...(!propertiesPermission && {
                            pointerEvents: "none",
                          }),
                        }}
                        to={`/properties/${insption_data?.property?.id}`}
                      >
                        <button className="next-btn ripple">
                          <FontAwesomeIcon icon={faCaretRight} />
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-7">
                  <p className="TabsLabel d-flex">Status</p>
                  <div className="d-flex status-text">
                    <CircleProgress
                      styleBig={true}
                      progressName={insption_data?.status}
                    />
                    <p>{checkStatus(insption_data?.status)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6">
              <p className="TabsLabel d-flex mb-3">Appointment Details</p>

              <div className="row">
                <div className="col-md-6 " style={{ height: "54px" }}>
                  <DatePicker
                    value={datet}
                    setValue={(v) => setdatet(v)}
                    label="Appointment Date"
                    disabled={!editEnabled}
                  />
                </div>

                <div className="col-md-6" style={{ height: "54px" }}>
                  <TimePicker
                    value={timet}
                    setValue={(v) => settimet(v)}
                    label="Appointment Time"
                    disabled={!editEnabled}
                  />
                </div>

                <div className="col-md-12 pd-top-notes">
                  <div className="form-group">
                    <div className="float-label">
                      <textarea
                        id="additional_remarks"
                        className="form-control input-field"
                        type="text"
                        maxLength="100"
                        disabled={!editEnabled}
                        value={insption_data?.notes}
                        onChange={(e) =>
                          setInsption_data({
                            ...insption_data,
                            notes: e.target.value,
                          })
                        }
                        style={{ minHeight: "100px" }}
                      />
                      <label
                        className={`${
                          insption_data.notes !== "" ? "Active " : ""
                        }auth-input-field`}
                        htmlFor="stat"
                      >
                        Notes
                      </label>
                    </div>
                    <small className="text-danger ml-1">
                      {editEnabled && insption_data.notes === ""
                        ? "Notes cannot be empty"
                        : errors?.notes
                        ? errors?.notes[0]
                        : ""}
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <p className="TabsLabel d-flex mb-3   ">Chain of Custody</p> 
              <button
                className="btn btn-primary"
                disabled={false}
                onClick={() => openCOC()}
                id="generate_coc"
              >
                Generate COC
              </button>
              <Suspense fallback={null}>
                <GenerateCOCModal
                  show={generateModalShow}
                  onHide={() => setgenerateModalShow(false)}
                  update={() => setupdate(update + 1)}
                  insp_id={insp_id}
                  insp_address={`${insption_data?.code} ${getAddress(
                    insption_data?.property?.address
                  )}`}
                  refresh={() => setRefreshInfo((s) => !s)}
                />
              </Suspense>
            </div>

            <div className="col-md-2">
              <p className="TabsLabel d-flex mb-3">Inspection Report</p>

              <Link
                style={{ textDecoration: "none" }}
                to={`/inspections/${insp_id}/generate-report`}
                id="generate_report"
              >
                <button
                  className="btn btn-primary"
                  // disabled={
                  //   !(
                  //     insption_data?.status === "coc_sent" ||
                  //     insption_data?.status === "finished"
                  //   )
                  // }
                  id="generate_report_btn"
                >
                  Generate Report
                </button>
              </Link>
            </div>
            {isSampleStrategyReportShown(user) ? (
              <div className="col-md-2">
                <p className="TabsLabel d-flex mb-3">Sample Strategy</p>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/inspection/${insp_id}/sample-strategy-report`}
                  id="sample_report"
                >
                  <button className="btn btn-primary" id="sample_report_btn">
                    Generate Sample
                  </button>
                </Link>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
}
