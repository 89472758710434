import Chip from "main/components/Chip";
import CustomInput from "main/components/Custom/CustomInput";
import { getHtmlTextLength } from "main/components/Editor/ReportEditor";
import { getErrorMsg, showToast } from "main/shared/helpers";
import { regexes } from "main/shared/regexes";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { customEmailSend } from "services/documents";
import { components } from "react-select";
import { getTemplateForDoc } from "services/emailTemplates";
import SimpleEditor from "../../../../components/Editor/SimpleEditor";
import { AsyncPaginate } from "react-select-async-paginate";
import "./style.css";

import { getTemplateForMultipleDoc } from "../../../../../services/emailTemplates";
import axios from "axios-defaults";

// import {
//   getTemplateForMultipleDoc,
// } from "../../../../../services";

export default function EmailSender(props) {
  const { show, documents, inspection_id } = props;
  console.log(documents);
  const [email, setEmail] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [selectedSampleType, setSelectedSampleType] = useState(null);
  const [selectedTestType, setSelectedTestType] = useState(null);

  const [sampleTypesData, setSampleTypesData] = useState([]);
  const [sampleInfoLabel, setSampleInfoLabel] = useState([]);

  useEffect(() => {
    const func = async () => {
      if (show) {
        let isMultiple = "";
        if (documents.length > 1) {
          isMultiple = true;
        }
        const { id } = documents[0];
        if (id) {
          await getData(id, isMultiple);
        }
      }
    };
    func();
    // console.log(emailTemplatesList,"============");
  }, [documents, show]);

  const data1 = useSelector((state) => state.user.data);

  const getData = async (id, isMultiple) => {
    const { data, error } = await getTemplateForDoc(id, isMultiple);
    setEmailList(data.other_info?.meta?.emails ?? []);
    if (error) {
      showToast(getErrorMsg(data) || "Unable to fetch Email Template.");
      return;
    }
    if (documents.length <= 1) {
      setBody(data.body ?? "");
      setSubject(data.subject ?? "");
      window.$("#email-trumbowyg-editor").trumbowyg("html", data.body ?? "");
    }
  };

  const handleClose = () => {
    props.onHide();
    setEmail("");
    setEmailList([]);
    setSubject("");
    setBody("");
  };

  const handleSend = (html) => {
    if (email) {
      setErrorText("Add email in list by pressing enter.");
      return;
    } else {
      setErrorText("");
    }
    if (emailList.length === 0) {
      showToast("Please add atleast one email address");
      return;
    } else if (!subject) {
      showToast("Subject is required");
      return;
    } else if (getHtmlTextLength(html) === 0) {
      showToast("Body is required");
      return;
    }

    setIsLoading(true);
    const data = {
      email_to: emailList,
      subject,
      message: html,
      attachments: documents.map((doc) => doc.id),
    };

    console.log(data);
    customEmailSend(data)
      .then(() => {
        setIsLoading(false);
        showToast("Email sent successfully.", true);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        showToast(getErrorMsg(err) || "Unable to send Email.");
        setIsLoading(false);
      });
  };

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      if (!email) {
        return;
      }
      if (!regexes.email.test(email)) {
        setErrorText("Invalid email address.");
        return;
      }

      if (emailList.includes(email.trim())) {
        setErrorText("Email already exists.");
        return;
      }

      setErrorText("");
      setEmailList([...emailList, email.trim()]);
      setEmail("");
    }
  };

  const handleRemoveEmail = (i) => {
    const newEmailList = emailList.filter((_, index) => index !== i);
    setEmailList(newEmailList);
  };

  const templateApiCall = async (ev) => {
    await getTemplateForMultipleDoc(inspection_id, ev?.value)
      .then((x) => {
        console.log(x, "qqq");
        window
          .$("#email-trumbowyg-editor")
          .trumbowyg("html", x.data.body ?? "");
        // setEmailList(x.data.other_info?.meta?.emails ?? []);
        setBody(x.data.body);
        setSubject(x.data.subject ?? "");

        // setIsLoading(false);
        // showToast("Email sent successfully.", true);
        // handleClose();
      })
      .catch((err) => {
        console.log(err);
        showToast(getErrorMsg(err) || "Unable to send Email.");
        setIsLoading(false);
      });
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {}}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="dialogbody">
          <div className="d-flex flex-column email-sender-form">
            <div className="d-flex flex">
              <div className="d-flex flex-column w-100 mx-4">
                <label className="e-label">To</label>
                <CustomInput
                  label="Enter mail address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  inputProps={{
                    onKeyDown: handleEnter,
                    type: "email",
                  }}
                />
                <small className="text-danger ml-2">{errorText || " "}</small>
              </div>
              <div className="d-flex flex-column w-100 mx-4">
                <label className="e-label">From</label>
                <CustomInput
                  label="Enter mail address"
                  value={data1?.business_unit?.company_name}
                  inputProps={{ readOnly: true }}
                />
              </div>
            </div>

            <div className="d-flex mx-4 mt-3 overflow-auto">
              {emailList.map((item, index) => (
                <Chip
                  id={`email-${index}`}
                  onCross={() => handleRemoveEmail(index)}
                  style={{ margin: "0px 5px 5px 0px" }}
                  key={`email-${index}`}
                >
                  {item}
                </Chip>
              ))}
            </div>

            {/* <div className=""> */}
            {documents.length > 1 ? (
              <div
                className="mt-3 d-flex flex-column w-30 mx-4"
                style={{ width: "480px" }}
              >
                <label className="e-label">Email Template</label>
                <SearchDropdownCustom
                  saveProp={"label"}
                  showProp={["name"]}
                  placeHolder="Select Email Template"
                  onSelect={(ev) => {
                    templateApiCall(ev);
                    setSelectedTestType(ev);
                    setSelectedSampleType(null);
                  }}
                  setSampleTypesData={setSampleTypesData}
                  setSampleInfoLabel={setSampleInfoLabel}
                />
              </div>
            ) : null}

            <div className="d-flex flex-column mx-4 my-3">
              <label className="e-label">Subject</label>
              <CustomInput
                label="Enter you subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>

            <div className="mx-4 small-trumboyg">
              <label className="e-label">Body</label>
              <SimpleEditor
                text={body}
                onSave={handleSend}
                maxLength={10000}
                onCancel={handleClose}
                disableSave={isLoading}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function SearchDropdownCustom(props) {
  const [value, onChange] = useState(null);
  const {
    apiCall,
    saveProp,
    showProp,
    placeHolder,
    onSelect,
    complicated,
    disabled,
    setSampleTypesData,
    setSampleInfoLabel,
  } = props;

  const [data, setData] = useState([
    { value: "main_report", label: "Main Report" },
    { value: "coc", label: "COC" },
    { value: "receipt", label: "Receipt Inovice" },
    { value: "laboratory_result", label: "Laboratory Result" },
    { value: "sample_strategy", label: "Sample Strategy" },
  ]);

  function loadOptions(search, loadedOptions, { page }) {
    var val = null;
    // var totalpage = { current_page: null, last_page: null };

    val = data.map((d) => {
      let temp = "";
      temp = d.label;

      return {
        value: d.value,
        label: temp,
      };
    });
    let filteredOptions;

    if (!search) {
      filteredOptions = val;
    } else {
      const searchLower = search.toLowerCase();
      filteredOptions = val.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    // const hasMore =
    //   totalpage?.current_page >= totalpage?.last_page ? false : true;
    // console.log(totalpage, "pages");
    return {
      options: filteredOptions,
      hasMore: null,
      additional: {
        page: page + 1,
      },
    };
  }
  return (
    <div>
      <AsyncPaginate
        isClearable={true}
        components={{
          Input: (props) => <components.Input {...props} maxLength={10} />,
        }}
        debounceTimeout={1000}
        value={value}
        loadOptions={loadOptions}
        onChange={(val) => {
          console.log(val, "222");
          onChange(val);
          onSelect(val);
          if (val) {
            setSampleTypesData(data.find((item) => item.id === val.value));
            setSampleInfoLabel(data.find((item) => item.id === val.value));
          }
        }}
        placeholder={placeHolder}
        additional={{
          page: 1,
        }}
        isDisabled={disabled}
      />
    </div>
  );
}
