export const emails = [
  { email: "bu@example.com" }, // Dev
  { email: "lopez_co@mailinator.com"},
  { email: "charles_co@mailinator.com" }, // QA
  { email: "reports@yesweinspect.com" }, // Prod
  {email: "harry_kane@mailinator.com" }, // Prod
];

export const isSampleStrategyReportShown = (user) => {
  const companyEmail = user?.data?.email || "";
  return emails.find((email) => email.email.trim() === companyEmail.trim());
};


//https://backend.scoutproportal.com/dev/api/v1/account

// show_sample_strategy = truev