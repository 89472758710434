import "./sample_strategy_styles.css";

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  columnsNames,
  discountList,
  sampleReasonList,
} from "./utility/constant";
import {
  getInspectorListAction,
  getReportDataAction,
  getTestListAction,
  handleFlagSelectionAction,
  handleRowSelectionAction,
  handleAllSelectionAction,
  updateStrategyReportTableListAction,
} from "store/actions/sample_strategy/sampleStrategyAction";
import { useDispatch, useSelector } from "react-redux";

import ActionButton from "./ActionButton/ActionButton";
import AddOns from "./AddOns/AddOns";
import CustomTable from "main/components/Customtable/CustomTable";
import ReportHeaderInfo from "./ReportHeaderInfo/ReportHeaderInfo";
import Summary from "./Summary/Summary";
import { convertLabelIntoValueAndOption } from "main/shared/helpers";
import { getColumnsOfReportTable } from "./utility/helper";
import styles from "./sample_strategy.module.css";
import { useParams, useHistory } from "react-router-dom";
import { isSampleStrategyReportShown } from "./utility/companySpecific";


 let selectAllRows = false; 

const SampleStrategyReport = () => {
  const { insp_id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const sampleStrategyReport = useSelector(
    (state) => state.sampleStrategyReport
  );
  const {
    testList,
    testListLoading,
    inspectorListLoading,
    getReportLoading,
    reportData,
    selectedRows,
    saveReportDataLoading,
    publishReportDataLoading,
    refreshReportDataLoading,
  } = sampleStrategyReport;


  
  const [isDisplayHiddenColumns, setIsDisplayHiddenColumns] = useState(true);

  const [validationError, setValidationError] = useState(
    Object.keys(columnsNames).reduce((acc, key) => {
      acc[columnsNames[key].value] = {
        error: false,
        message: "",
      };
      return acc;
    }, {})
  );

  const testListOptions =
    convertLabelIntoValueAndOption(testList, "testId", "testName") || [];
  const discountListOptions =
    convertLabelIntoValueAndOption(discountList, "id", "name") || [];
  const sampleReasonListOptions =
    convertLabelIntoValueAndOption(sampleReasonList, "id", "name") || [];

  const handleAfterSaveCell = async (oldValue, newValue, row, column) => {
    try {
      const { dataField } = column;
      await dispatch(updateStrategyReportTableListAction(row, dataField));
    } catch (e) {
      console.log(e);
    }
  };

  const handleHiddenColumn = () => {
    setIsDisplayHiddenColumns(!isDisplayHiddenColumns);
  };

  const handleFlagSelection = (row) => {
    dispatch(handleFlagSelectionAction(row));
  };

  const handleSelectRow = (row) => {
    dispatch(handleRowSelectionAction(row));
  };


  const handleAllSelectRow = (e) => {
    dispatch( handleAllSelectionAction(e));
  };


  const getReportData = async () => {
    try {
      await dispatch(getReportDataAction(insp_id));
    } catch (error) {
      console.log("error", error);
    }
  };

  const getTestList = async () => {
    try {
      await dispatch(getTestListAction());
    } catch (error) {
      console.log("error", error);
    }
  };

  const getInspectorList = async () => {
    try {
      await dispatch(getInspectorListAction());
    } catch (error) {
      console.log("error", error);
    }
  };

  const rowClasses = (row) => {
    if (row[columnsNames.client_approval.value]) {
      return "client_approved";
    } else if (row.status) {
      return "selected-row";
    }
  };

  const isLoading = () => {
    return testListLoading || inspectorListLoading || getReportLoading;
  };

  const dbUpdateLoading = () => {
    return (
      saveReportDataLoading ||
      publishReportDataLoading ||
      refreshReportDataLoading
    );
  };

  useEffect(() => {
    getTestList();
    getInspectorList();
    getReportData();
  }, []);

  useEffect(() => {
    if (!isSampleStrategyReportShown(user)) {
      history.goBack();
    }
  }, []);


useEffect(()=> {
  selectAllRows = reportData?.sample_strategy_report?.every((r) => r[columnsNames.status.value] === true);
}, [reportData?.sample_strategy_report])


  return (
    <div className={styles.container}>
      {isLoading() ? (
        <div className={styles.loader_container}>
          <div className={styles.loader}></div>
        </div>
      ) : (
        <div className={`${styles.report_container}`}>
          {dbUpdateLoading() ? (
            <div className={styles.loader_overlay}>
              <div className={styles.loader}></div>
            </div>
          ) : (
            <></>
          )}
          <div className={styles.report_container_content}>
            <ActionButton />
            <ReportHeaderInfo />
            <div className={styles.reportTitle}>Recommended Sampling Plan</div>
            <div className={styles.table_container}>
              <CustomTable
                keyField={"id"}
                timeToCloseMessage={1000000}
                hideEditCells={false}
                hideRowSelection={true}
                columns={getColumnsOfReportTable({
                  testListOptions,
                  isDisplayHiddenColumns,
                  handleHiddenColumn,
                  discountOptions: discountListOptions,
                  sampleReasonOptions: sampleReasonListOptions,
                  handleFlagSelection: handleFlagSelection,
                  handleSelectRow: handleSelectRow,
                  selectAllRows: selectAllRows,
                  handleAllSelectRow: handleAllSelectRow,
                  validationError,
                  setValidationError,
                  data: reportData?.sample_strategy_report?.length
                    ? reportData.sample_strategy_report
                    : [],
                })}
                data={
                  reportData?.sample_strategy_report?.length
                    ? reportData.sample_strategy_report
                    : []
                }
                rowClasses={rowClasses}
                handleAfterSaveCell={handleAfterSaveCell}
              />
            </div>
            <div className={styles.reportFooter}>
              <div className={styles.summary}>
                <Summary />
              </div>
              <div className={styles.addon}>
                <AddOns />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SampleStrategyReport;
