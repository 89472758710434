import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { showToast } from "main/shared/helpers";
import { refreshReportDataAction } from "store/actions/sample_strategy/sampleStrategyAction";
import { useParams } from "react-router-dom";


import { Button } from "react-bootstrap";
import DiscountModal from "./DiscountModal/DiscountModal";
import ExpediteModal from "./ExpediteModal/ExpediteModal";
import FinancialModal from "./FinancialModal/FinancialModal";
import styles from "./addOn.module.css";


const AddOns = (props) => {
  const { expedites = [], sampleCount = [] } = props;
  const { insp_id } = useParams();
  const dispatch = useDispatch();
  const sampleStrategyReport = useSelector(
    (state) => state.sampleStrategyReport
  );

  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [showExpediteModal, setShowExpediteModal] = useState(false);
  const [showFinancialModal, setShowFinancialModal] = useState(false);

  const toggleDiscountModal = () => {
    setShowDiscountModal(!showDiscountModal);
  };
  const toggleExpediteModal = () => {
    setShowExpediteModal(!showExpediteModal);
  };
  const toggleFinancialModal = () => {
    setShowFinancialModal(!showFinancialModal);
  };

  const handleRefresh = async () => {
    try {
      await dispatch(refreshReportDataAction(insp_id));
      showToast("Data Refresh Successfully", true);
    } catch (e) {
      console.log(e);
      showToast(e?.message);
    }
  };

  return (
    <>
      <div className={styles.summary_footer}>
        <div className={styles.discountBtnCnt}>
        <Button onClick={handleRefresh} className={styles.anchor_btn} size="sm">
          Refresh
        </Button>
        <Button onClick={toggleDiscountModal} className={styles.anchor_discount_btn} size="sm">
          Discount
        </Button>
        </div>
      <div className={styles.container}>
        <Button
          className={styles.btnExpedite}
          size="sm"
          onClick={toggleExpediteModal}
        >
          Expedite
        </Button>
        <Button
          className={styles.btnFinancial}
          size="sm"
          onClick={toggleFinancialModal}
        >
          Financing
        </Button>
      </div>
      </div>
      <DiscountModal showDiscountModal={showDiscountModal} toggleDiscountModal={toggleDiscountModal} />
      <ExpediteModal expedites={expedites} sampleCount={sampleCount} showExpediteModal={showExpediteModal} toggleExpediteModal={toggleExpediteModal} />
      <FinancialModal showFinancialModal={showFinancialModal} toggleFinancialModal={toggleFinancialModal} />
    </>
  );
};

export default AddOns;
