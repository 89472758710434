import React, { useState } from "react";
import styles from "./action_btn.module.css";
import { Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "main/shared/helpers";
import { publishReportDataAction, saveReportDataAction, getReportDataActionCTA } from "store/actions/sample_strategy/sampleStrategyAction";
import { useParams } from "react-router-dom";

const ActionButton = () => {
  const { insp_id } = useParams();

  const dispatch = useDispatch();
  const sampleStrategyReport = useSelector(
    (state) => state.sampleStrategyReport
  );
  const { testList, reportData, selectedRows } = sampleStrategyReport;

  const handleOnReload = async () => {
    try {
      await dispatch(getReportDataActionCTA(insp_id, reportData, testList));
      showToast("Got all Changes Successfully", true);
    } catch (e) {
      console.log(e);
      showToast(e?.message);
    }
  };

  const handleOnSave = async () => {
    try {
      await dispatch(saveReportDataAction(insp_id));
      showToast("Report Save Successfully", true);
    } catch (e) {
      console.log(e);
      showToast(e?.message);
    }
  };


  const handlePublish = async (type) => {
    try {
      if (selectedRows.length === 0) {
        showToast("Please select at least one row to publish");
        return;
      }
      await dispatch(publishReportDataAction(insp_id, type));
      showToast("Report Publish Successfully", true);
    } catch (e) {
      console.log(e);
      showToast(e?.message);
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.action_buttons}>

        <Button onClick={handleOnSave} className={styles.save_btn} size="sm">
          Save
        </Button>
        <Button
          onClick={handleOnReload}
          className={styles.refresh_btn}
          size="sm"
        >
          Reload
        </Button>
        {/* <Button onClick={handlePublish}  size="sm">
          Publish
        </Button> */}
        <Dropdown className={styles.dropDownCnt}>
          <Dropdown.Toggle className={styles.publish_btn} size="sm" id="dropdown-basic">
            Publish
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item className="custom-dropdown-item" onClick={() => handlePublish('full')}>Full Receipt</Dropdown.Item>
            <Dropdown.Item className="custom-dropdown-item" onClick={() => handlePublish('half')}>Sample-Only Invoice</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default ActionButton;
